import React from "react";
import WindowManager from "./WindowManager";

export function SynOs() {
  return (
    <>
      <WindowManager />
    </>
  );
}
